
import Vue from 'vue'
import RocketPharmLogo from 'icons/rph-logo.svg?inline'
import RPHHeaderNav from 'rph/molecules/RPHHeaderNav/index.vue'
import RPHSearchBar from 'rph/organisms/RPHSearchBar/index.vue'
import RPHHeaderLocation from 'rph/organisms/RPHHeaderLocation/index.vue'
import RPHHeaderButtonGroup from 'rph/organisms/RPHHeaderButtonGroup/index.vue'
import RPHTextBadge from 'rph/atoms/RPHTextBadge/index.vue'

/**
 * RPHHeader component v2
 */
export default Vue.extend({
  name: 'RPHHeader',
  components: {
    RPHHeaderNav,
    RocketPharmLogo,
    RPHSearchBar,
    RPHHeaderLocation,
    RPHHeaderButtonGroup,
    RPHTextBadge,
  },
  props: {
    headerHideOffset: {
      type: Number,
      default: 120,
    },
    bottomSearch: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isCollapsed: false,
      isMounted: false,
    }
  },

  async fetch() {
    if (!this.$store.getters['rph-menu/menu'].length) {
      await this.$store.dispatch('rph-menu/getMenu')
    }
  },

  computed: {
    isAuth(): boolean {
      // @ts-ignore
      return this.$auth.loggedIn
    },

    isPharmacyPage(): boolean {
      return !!this.$route.query.pharmacy_id
    },

    isMainPage(): boolean {
      return this.$route.fullPath === '/'
    },

    mindboxUserId(): string {
      return this.$store.getters['rph-users/mindboxUserId']
    },
  },
  watch: {
    async isAuth(val) {
      if (val) {
        try {
          this.$store.commit('rph-profile/setAddressPresent', false)
          let cartId = await this.$services.localStorageBasket.getBasketId('basketId')
          cartId = String(await this.$services.productInBasket.concatBasket(cartId || ''))
          this.$services.localStorageBasket.setBasketId(cartId)
          this.$store.commit('rph-basket/setBasketId', cartId)
          await this.$store.dispatch('rph-basket/getBasketProducts', {
            id: cartId,
            params: {
              params: {
                expand:
                  'basketProducts,basketProducts.product,basketProducts.product.prices,basketProducts.product.images,basketProducts.product.category,basketProducts.product.producers,basketProducts.product.pickup',
              },
            },
          })
          this.$store.commit('rph-profile/setAddressPresent', true)
        } catch (error) {
          console.log('Ошибка при загрузке данных корзины:', error)
        } finally {
          this.$store.commit('rph-profile/setAddressPresent', true)
        }
      }
    },
    mindboxUserId(val) {
      if (val !== null) {
        this.$store.dispatch('rph-users/getUserBonusPoints', this.mindboxUserId)
      }
    },
  },

  async beforeMount() {
    if (!this.$store.getters['rph-basket/productsInit']) {
      this.$nextTick(() => {
        this.$nuxt.$loading.start()
      })
      let cartId = await this.$services.localStorageBasket.getBasketId('basketId')
      if (this.isAuth) {
        cartId = String(await this.$services.productInBasket.concatBasket(cartId || ''))
        this.$services.localStorageBasket.setBasketId(cartId)
      }
      await this.$store.dispatch('rph-basket/getBasketProducts', {
        id: cartId,
        params: {
          params: {
            expand:
              'basketProducts,basketProducts.product,basketProducts.product.prices,basketProducts.product.images,basketProducts.product.category,basketProducts.product.producers,pharmacyPrices',
          },
        },
      })
      this.$nextTick(() => {
        this.$nuxt.$loading.finish()
      })
    }
    if (!this.$store.getters['rph-profile/addresses'].length) {
      await this.$store.dispatch('rph-profile/getAddresses')
    }
  },
  mounted() {
    this.isMounted = true
    window.addEventListener('scroll', this.onScrollHandler.bind(this))
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScrollHandler.bind(this))
  },
  methods: {
    onScrollHandler() {
      const st = window.scrollY
      const headerHeight = this.headerHideOffset
      this.isCollapsed = st > headerHeight
      this.$store.commit('rph-header/setCollapsed', this.isCollapsed)
    },
  },
})
