
import Vue from 'vue'
import Basket from 'icons/basket-cart--desktop.svg?inline'
import RPHButton from 'rph/atoms/RPHButton/index.vue'
import RPHBadge from 'rph/atoms/RPHBadge/index.vue'

export default Vue.extend({
  name: 'RPHHeaderBasket',
  components: {
    Basket,
    RPHBadge,
    RPHButton,
  },

  methods: {
    async goToCart() {
      if (this.$route.path !== '/delivery') {
        // @ts-ignore: Unreachable code error
        await this.$gtm.push({ event: 'click_on_cart' })
      }
      await this.$router.push('/delivery-pickup')
    },
  },
  computed: {
    listOfProducts(): any {
      return this.$store.getters['rph-basket/basketList']
    },
    basketItemsCount(): any {
      return this.listOfProducts.reduce((acc: number, item: any) => {
        return (acc += item.count)
      }, 0)
    },
    count(): number {
      return this.$store.getters['rph-basket/totalCount']
    },
    // prices(): any {
    //   return this.$store.getters['rph-basket/cartPrice']
    // },
    // basketPrice(): any {
    //   return Math.min(this.prices?.pickup?.discount, this.prices?.delivery?.discount) || 0
    // },
  },
})
